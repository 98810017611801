import React from 'react'
import styled from 'styled-components'
import H2 from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import Form from 'components/forms/promotions'
import Layout from 'components/layout'
import Content from 'components/content'
import ShopLinks from 'components/shop-links'
import { graphql } from 'gatsby'

const PromotionsPage = ({
  data: {
    allHutsonPromotionsImage,
    constructionEquipmentImage,
    mowersImage,
    partsImage,
    serviceImage,
    stihlImage,
    tractorPackagesImage,
  },
}) => {
  const shopLinks = [
    {
      image: partsImage.childImageSharp.gatsbyImageData,
      title: 'Parts',
      description: 'View current parts promotions at Hutson',
      link: '/promotions/parts/',
      linkText: 'View',
    },
    {
      image: serviceImage.childImageSharp.gatsbyImageData,
      title: 'Service',
      description: 'View current service promotions at Hutson',
      link: '/promotions/service/',
      linkText: 'View',
    },
    {
      image: tractorPackagesImage.childImageSharp.gatsbyImageData,
      title: 'Tractor Packages',
      description: 'View current tractor package offers at Hutson',
      link: '/promotions/tractor-packages/',
      linkText: 'View',
    },
    {
      image: mowersImage.childImageSharp.gatsbyImageData,
      title: 'John Deere',
      description: 'View current John Deere promotions at Hutson',
      link: '/promotions/john-deere/',
      linkText: 'View',
    },
    {
      image: constructionEquipmentImage.childImageSharp.gatsbyImageData,
      title: 'Compact Construction Equipment',
      description: 'View current CCE promotions at Hutson',
      link: '/promotions/compact-construction-equipment/',
      linkText: 'View',
    },
    {
      image: stihlImage.childImageSharp.gatsbyImageData,
      title: 'STIHL',
      description: 'View current STIHL promotions at Hutson',
      link: '/promotions/stihl/',
      linkText: 'View',
    },
    {
      image: allHutsonPromotionsImage.childImageSharp.gatsbyImageData,
      title: 'All Promotions',
      description: 'View all current promotions at Hutson',
      link: '/promotions/all/',
      linkText: 'View',
    },
  ]
  return (
    <Layout kind='white'>
      <Helmet>
        <title>Promotions | Hutson Inc</title>
        <meta
          name='description'
          content='Check out the latest promotions, offers, and discounts from Hutson, Inc. Find discounts on parts, service and equipment.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Promotions',
                'item': 'https://www.hutsoninc.com/promotions/',
              },
            ],
          })}
        </script>
      </Helmet>

      <PromotionLinks>
        <Wrapper>
          <H2 type='h1' textAlign='center'>
            Current Promotions at Hutson
          </H2>
        </Wrapper>
        <ShopLinks links={shopLinks} />
      </PromotionLinks>

      <Content>
        <H2 textAlign='center'>Sign up for our promotions newsletter</H2>
        <Form />
      </Content>
    </Layout>
  )
}

const PromotionLinks = styled.div`
  padding: 40px 0 0;

  @media (min-width: 800px) {
    padding: 80px 0 0;
  }
`

const Wrapper = styled.div`
  margin: auto;
  max-width: 85%;
`

export const pageQuery = graphql`
  {
    partsImage: file(relativePath: { eq: "shop-links/parts.jpg" }) {
      ...SharpImage600
    }
    serviceImage: file(relativePath: { eq: "locations/service.jpg" }) {
      ...SharpImage600
    }
    tractorPackagesImage: file(relativePath: { eq: "shop-links/tractor-packages.jpg" }) {
      ...SharpImage600
    }
    mowersImage: file(relativePath: { eq: "locations/mowers.jpg" }) {
      ...SharpImage600
    }
    constructionEquipmentImage: file(relativePath: { eq: "locations/construction-equipment.jpg" }) {
      ...SharpImage600
    }
    stihlImage: file(relativePath: { eq: "shop-links/stihl.jpg" }) {
      ...SharpImage600
    }
    allHutsonPromotionsImage: file(relativePath: { eq: "shop-links/all-promotions.jpg" }) {
      ...SharpImage600
    }
  }
`

export default PromotionsPage
